import * as React from 'react';

import { GlobalStyle } from '../styles/global';
import logo from '../images/logo-horizonte.svg';
import zap from '../images/whatsapp.svg';
import video from '../images/video/video.mp4';

import {
  SectionHome,
  ContentHome,
  ContentImg,
  ContentText
} from '../styles/home';

const IndexPage = () => {
  return (
    <main id="page-wrap">
      <GlobalStyle />
      <title>
        Horizonte - Capacitação e geração de emprego no mercado de tecnologia.
      </title>
      <SectionHome>
        <video className="video" autoPlay loop muted>
          <source src={video} type="video/mp4" />
        </video>
        <ContentHome>
          <ContentImg
            data-sal="slide-right"
            data-sal-delay="200"
            data-sal-easing="ease"
          >
            <img src={logo} alt="logo" />
            <h1>Site em Construção!</h1>
          </ContentImg>
          <ContentText
            data-sal="slide-left"
            data-sal-delay="200"
            data-sal-easing="ease"
          >
            <h1>Do Zero ao Herói.</h1>
            <p>
              O programa que capacita, gera empregos e promove a diversidade no
              mercado de tecnologia.
            </p>
            <p>
              Desculpe o transtorno mas nossos devs estão estudando e
              desenvolvendo esse site para que você conheça o resultado do nosso
              treinamento através do nosso site. Se deseja saber mais sobre o
              projeto envie uma mensagem para nosso whatsapp
              <br />
              <strong className="strong">
                <a
                  href="https://api.whatsapp.com/send?phone=5521968366814&text="
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={zap} alt="zap" />
                  (21) 96836-6814
                </a>
              </strong>
            </p>
          </ContentText>
        </ContentHome>
      </SectionHome>
    </main>
  );
};

export default IndexPage;
