import styled, { createGlobalStyle } from "styled-components";
import { Link } from "gatsby";

const ThemeConfig = {
  text: "#313243",
  background: "#fff",
  white: "#fff",
  primary: "#4AFBFE",
  secondary: "#F5E84B",
  hover: "#55578D",
  backgroundF: "#3C3C41",
  textF: "#A3A3B2",
  modes: {
    dark: {
      text: "#fff",
      background: "#303153",
      video: "rgba(48, 49, 83, 0.9)",
    },
    light: {
      text: "#313243",
      background: "#ffffff",
    },
  },
};

const GlobalStyle = createGlobalStyle`
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap');
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: "Poppins", georgia, serif;
  }
  body {
    background: ${ThemeConfig.modes.dark.background};
  }
  @-webkit-keyframes myOrbit {
    from { -webkit-transform: rotate(0deg) translateX(230px) rotate(0deg); }
    to   { -webkit-transform: rotate(360deg) translateX(230px) rotate(-360deg); }
}

@-moz-keyframes myOrbit {
    from { -moz-transform: rotate(0deg) translateX(230px) rotate(0deg); }
    to   { -moz-transform: rotate(360deg) translateX(230px) rotate(-360deg); }
}

@-o-keyframes myOrbit {
    from { -o-transform: rotate(0deg) translateX(230px) rotate(0deg); }
    to   { -o-transform: rotate(360deg) translateX(230px) rotate(-360deg); }
}

@keyframes myOrbit {
    from { transform: rotate(0deg) translateX(230px) rotate(0deg); }
    to   { transform: rotate(360deg) translateX(230px) rotate(-360deg); }
}

/*AMAZON ICON*/
@-webkit-keyframes animateAmazon {
  from { -webkit-transform: rotate(0deg) translateX(70px) rotate(0deg); }
  to   { -webkit-transform: rotate(360deg) translateX(70px) rotate(-360deg); }
}

@-moz-keyframes animateAmazon {
  from { -moz-transform: rotate(0deg) translateX(70px) rotate(0deg); }
  to   { -moz-transform: rotate(360deg) translateX(70px) rotate(-360deg); }
}

@-o-keyframes animateAmazon {
  from { -o-transform: rotate(0deg) translateX(70px) rotate(0deg); }
  to   { -o-transform: rotate(360deg) translateX(70px) rotate(-360deg); }
}

@keyframes animateAmazon {
  from { transform: rotate(0deg) translateX(70px) rotate(0deg); }
  to   { transform: rotate(360deg) translateX(70px) rotate(-360deg); }
}

/*BOOTSTRAP ICON*/
@-webkit-keyframes animateBootstrap {
  from { -webkit-transform: rotate(0deg) translateX(-230px) rotate(0deg); }
  to   { -webkit-transform: rotate(360deg) translateX(-230px) rotate(-360deg); }
}

@-moz-keyframes animateBootstrap {
  from { -moz-transform: rotate(0deg) translateX(-230px) rotate(0deg); }
  to   { -moz-transform: rotate(360deg) translateX(-230px) rotate(-360deg); }
}

@-o-keyframes animateBootstrap {
  from { -o-transform: rotate(0deg) translateX(-230px) rotate(0deg); }
  to   { -o-transform: rotate(360deg) translateX(-230px) rotate(-360deg); }
}

@keyframes animateBootstrap {
  from { transform: rotate(0deg) translateX(-230px) rotate(0deg); }
  to   { transform: rotate(360deg) translateX(-230px) rotate(-360deg); }
}

@-webkit-keyframes animateCSS3 {
  from { -webkit-transform: rotate(0deg) translateX(230px) rotate(0deg); }
  to   { -webkit-transform: rotate(360deg) translateX(230px) rotate(-360deg); }
}

@-moz-keyframes animateCSS3 {
  from { -moz-transform: rotate(0deg) translateX(100px) rotate(0deg); }
  to   { -moz-transform: rotate(360deg) translateX(100px) rotate(-360deg); }
}

@-o-keyframes animateCSS3 {
  from { -o-transform: rotate(0deg) translateX(100px) rotate(0deg); }
  to   { -o-transform: rotate(360deg) translateX(100px) rotate(-360deg); }
}

@keyframes animateCSS3 {
  from { transform: rotate(0deg) translateX(100px) rotate(0deg); }
  to   { transform: rotate(360deg) translateX(100px) rotate(-360deg); }
}


@-webkit-keyframes animateNode {
  from { -webkit-transform: rotate(0deg) translateX(100px) rotate(0deg); }
  to   { -webkit-transform: rotate(360deg) translateX(100px) rotate(-360deg); }
}

@-moz-keyframes animateNode {
  from { -moz-transform: rotate(0deg) translateX(100px) rotate(0deg); }
  to   { -moz-transform: rotate(360deg) translateX(100px) rotate(-360deg); }
}

@-o-keyframes animateNode {
  from { -o-transform: rotate(0deg) translateX(100px) rotate(0deg); }
  to   { -o-transform: rotate(360deg) translateX(100px) rotate(-360deg); }
}

@keyframes animateNode {
  from { transform: rotate(0deg) translateX(100px) rotate(0deg); }
  to   { transform: rotate(360deg) translateX(100px) rotate(-360deg); }
}


@-webkit-keyframes animateGraphql {
  from { -webkit-transform: rotate(0deg) translateX(200px) rotate(0deg); }
  to   { -webkit-transform: rotate(360deg) translateX(200px) rotate(-360deg); }
}

@-moz-keyframes animateGraphql {
  from { -moz-transform: rotate(0deg) translateX(200px) rotate(0deg); }
  to   { -moz-transform: rotate(360deg) translateX(200px) rotate(-360deg); }
}

@-o-keyframes animateGraphql {
  from { -o-transform: rotate(0deg) translateX(200px) rotate(0deg); }
  to   { -o-transform: rotate(360deg) translateX(200px) rotate(-360deg); }
}

@keyframes animateGraphql {
  from { transform: rotate(0deg) translateX(200px) rotate(0deg); }
  to   { transform: rotate(360deg) translateX(200px) rotate(-360deg); }
}

@-webkit-keyframes animateJavascript {
  from { -webkit-transform: rotate(0deg) translateX(-150px) rotate(0deg); }
  to   { -webkit-transform: rotate(360deg) translateX(-150px) rotate(-360deg); }
}

@-moz-keyframes animateJavascript {
  from { -moz-transform: rotate(0deg) translateX(-150px) rotate(0deg); }
  to   { -moz-transform: rotate(360deg) translateX(-150px) rotate(-360deg); }
}

@-o-keyframes animateJavascript {
  from { -o-transform: rotate(0deg) translateX(-150px) rotate(0deg); }
  to   { -o-transform: rotate(360deg) translateX(-150px) rotate(-360deg); }
}

@keyframes animateJavascript {
  from { transform: rotate(0deg) translateX(-150px) rotate(0deg); }
  to   { transform: rotate(360deg) translateX(-150px) rotate(-360deg); }
}


@-webkit-keyframes animateReactJs {
  from { -webkit-transform: rotate(0deg) translateX(-270px) rotate(0deg); }
  to   { -webkit-transform: rotate(360deg) translateX(-270px) rotate(-360deg); }
}

@-moz-keyframes animateReactJs {
  from { -moz-transform: rotate(0deg) translateX(-270px) rotate(0deg); }
  to   { -moz-transform: rotate(360deg) translateX(-270px) rotate(-360deg); }
}

@-o-keyframes animateReactJs {
  from { -o-transform: rotate(0deg) translateX(-270px) rotate(0deg); }
  to   { -o-transform: rotate(360deg) translateX(-270px) rotate(-360deg); }
}

@keyframes animateReactJs {
  from { transform: rotate(0deg) translateX(-270px) rotate(0deg); }
  to   { transform: rotate(360deg) translateX(-270px) rotate(-360deg); }
}


@-webkit-keyframes animateTW {
  from { -webkit-transform: rotate(0deg) translateX(-50px) rotate(0deg); }
  to   { -webkit-transform: rotate(360deg) translateX(-50px) rotate(-360deg); }
}

@-moz-keyframes animateTW {
  from { -moz-transform: rotate(0deg) translateX(-50px) rotate(0deg); }
  to   { -moz-transform: rotate(360deg) translateX(-50px) rotate(-360deg); }
}

@-o-keyframes animateTW {
  from { -o-transform: rotate(0deg) translateX(-50px) rotate(0deg); }
  to   { -o-transform: rotate(360deg) translateX(-50px) rotate(-360deg); }
}

@keyframes animateTW {
  from { transform: rotate(0deg) translateX(-50px) rotate(0deg); }
  to   { transform: rotate(360deg) translateX(-50px) rotate(-360deg); }
}



@-webkit-keyframes animateWP {
  from { -webkit-transform: rotate(0deg) translateX(-180px) rotate(0deg); }
  to   { -webkit-transform: rotate(360deg) translateX(-180px) rotate(-360deg); }
}

@-moz-keyframes animateWP {
  from { -moz-transform: rotate(0deg) translateX(-180px) rotate(0deg); }
  to   { -moz-transform: rotate(360deg) translateX(-180px) rotate(-360deg); }
}

@-o-keyframes animateWP {
  from { -o-transform: rotate(0deg) translateX(-180px) rotate(0deg); }
  to   { -o-transform: rotate(360deg) translateX(-180px) rotate(-360deg); }
}

@keyframes animateWP {
  from { transform: rotate(0deg) translateX(-180px) rotate(0deg); }
  to   { transform: rotate(360deg) translateX(-180px) rotate(-360deg); }
}
`;

const ButtonPrimary = styled(Link)`
  background: ${(props) => props.background};
  color: ${(props) => props.color};
  text-decoration: none;
  padding: 10px 15px;
  border-radius: 5px;
  font-size: 15px;
  font-weight: 600;
`;

export { GlobalStyle, ThemeConfig, ButtonPrimary };
