import styled from "styled-components";
import { ThemeConfig } from "./global";
import stacksImage from "../images/home_circle/lines.png";
import backgroundCapacitacao from "../images/Grupo-mascara.svg";
import { Link } from "gatsby";

const SectionHome = styled.section`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${ThemeConfig.modes.dark.video};

  .video {
    position: absolute;
    width: 100vw;
    height: 100vh;
    z-index: -1;
    object-fit: cover;
  }
`;
const ImageIconHTML = styled.img`
  position: absolute;
  left: 270px;
  bottom: 200px;
  width: 50px;
  -webkit-animation: myOrbit 50s linear infinite; /* Chrome, Safari 5 */
  -moz-animation: myOrbit 50s linear infinite; /* Firefox 5-15 */
  -o-animation: myOrbit 50s linear infinite; /* Opera 12+ */
  animation: myOrbit 50s linear infinite;
`;
const ImageIconAmazon = styled.img`
  position: absolute;
  left: 250px;
  bottom: 250px;
  width: 50px;
  margin-right: -20px;
  margin-top: -20px;
  -webkit-animation: animateAmazon 40s linear infinite; /* Chrome, Safari 5 */
  -moz-animation: animateAmazon 40s linear infinite; /* Firefox 5-15 */
  -o-animation: animateAmazon 40s linear infinite; /* Opera 12+ */
  animation: animateAmazon 40s linear infinite;
`;
const ImageIconBootstrap = styled.img`
  position: absolute;
  left: 270px;
  bottom: 270px;
  width: 50px;
  margin-right: -25px;
  margin-top: -25px;
  -webkit-animation: animateBootstrap 160s linear infinite; /* Chrome, Safari 5 */
  -moz-animation: animateBootstrap 160s linear infinite; /* Firefox 5-15 */
  -o-animation: animateBootstrap 160s linear infinite; /* Opera 12+ */
  animation: animateBootstrap 160s linear infinite;
`;
const ImageIconCSS3 = styled.img`
  position: absolute;
  left: 250px;
  bottom: 250px;
  width: 50px;
  margin-right: -25px;
  margin-top: -25px;
  -webkit-animation: animateCSS3 60s linear infinite; /* Chrome, Safari 5 */
  -moz-animation: animateCSS3 60s linear infinite; /* Firefox 5-15 */
  -o-animation: animateCSS3 60s linear infinite; /* Opera 12+ */
  animation: animateCSS3 60s linear infinite;
`;
const ImageIconGraphql = styled.img`
  position: absolute;
  left: 250px;
  bottom: 250px;
  width: 50px;
  margin-right: -25px;
  margin-top: -25px;
  -webkit-animation: animateGraphql 65s linear infinite; /* Chrome, Safari 5 */
  -moz-animation: animateGraphql 65s linear infinite; /* Firefox 5-15 */
  -o-animation: animateGraphql 65s linear infinite; /* Opera 12+ */
  animation: animateGraphql 65s linear infinite;
`;
const ImageIconGatsby = styled.img`
  position: absolute;
  left: 250px;
  bottom: 250px;
  width: 50px;
  margin-right: -25px;
  margin-top: -25px;
  -webkit-animation: animateGraphql 80s linear infinite; /* Chrome, Safari 5 */
  -moz-animation: animateGraphql 80s linear infinite; /* Firefox 5-15 */
  -o-animation: animateGraphql 80s linear infinite; /* Opera 12+ */
  animation: animateGraphql 80s linear infinite;
`;

const ImageIconJavascript = styled.img`
  position: absolute;
  left: 250px;
  bottom: 250px;
  width: 50px;
  margin-right: -25px;
  margin-top: -25px;
  -webkit-animation: animateJavascript 70s linear infinite; /* Chrome, Safari 5 */
  -moz-animation: animateJavascript 70s linear infinite; /* Firefox 5-15 */
  -o-animation: animateJavascript 70s linear infinite; /* Opera 12+ */
  animation: animateJavascript 70s linear infinite;
`;

const ImageIconNodeJs = styled.img`
  position: absolute;
  left: 250px;
  bottom: 250px;
  width: 50px;
  margin-right: -25px;
  margin-top: -25px;
  -webkit-animation: animateNode 80s linear infinite; /* Chrome, Safari 5 */
  -moz-animation: animateNode 80s linear infinite; /* Firefox 5-15 */
  -o-animation: animateNode 80s linear infinite; /* Opera 12+ */
  animation: animateNode 80s linear infinite;
`;
const ImageIconReactJs = styled.img`
  position: absolute;
  left: 250px;
  bottom: 250px;
  width: 50px;
  margin-right: -25px;
  margin-top: -25px;
  -webkit-animation: animateReactJs 80s linear infinite; /* Chrome, Safari 5 */
  -moz-animation: animateReactJs 80s linear infinite; /* Firefox 5-15 */
  -o-animation: animateReactJs 80s linear infinite; /* Opera 12+ */
  animation: animateReactJs 80s linear infinite;
`;

const ImageIconTailwindcss = styled.img`
  position: absolute;
  left: 250px;
  bottom: 250px;
  width: 50px;
  margin-right: -25px;
  margin-top: -25px;
  -webkit-animation: animateTW 55s linear infinite; /* Chrome, Safari 5 */
  -moz-animation: animateTW 55s linear infinite; /* Firefox 5-15 */
  -o-animation: animateTW 55s linear infinite; /* Opera 12+ */
  animation: animateTW 55s linear infinite;
`;
const ImageIconWP = styled.img`
  position: absolute;
  left: 250px;
  bottom: 250px;
  width: 50px;
  margin-right: -25px;
  margin-top: -25px;
  -webkit-animation: animateWP 115s linear infinite; /* Chrome, Safari 5 */
  -moz-animation: animateWP 115s linear infinite; /* Firefox 5-115 */
  -o-animation: animateWP 115s linear infinite; /* Opera 12+ */
  animation: animateWP 115s linear infinite;
`;
const ContentHome = styled.div`
  max-width: 1280px;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 100px 30px;
  position: relative;

  @media (max-width: 768px) {
    flex-direction: column;
  }

  & h1 {
    font-family: "Poppins", sans-serif;
    font-size: 49px;
    font-weight: bold;
    color: ${ThemeConfig.secondary};
    margin-top: 5%;
    margin-bottom: 10px;

    @media screen and (max-width: 1366px) {
      font-size: 39px;
      margin-top: 20px;
    }
  }
  & p {
    font-family: "Poppins", sans-serif;
    font-size: 20px;
    font-weight: 400;
    color: ${ThemeConfig.modes.dark.text};
    max-width: 500px;
    margin-bottom: 40px;

    @media screen and (max-width: 1366px) {
      font-size: 14px;
    }
  }

  @media screen and (max-width: 1366px) {
    background-size: 523px;
    background-position-y: 10px;
    background-position-x: 669px;
  }
`;

const ContentImg = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  > img {
    width: 500px;

    @media (max-width: 1024px) {
      width: 350px;
    }

    @media (max-width: 768px) {
      width: 500px;
    }

    @media (max-width: 500px) {
      width: 320px;
    }
  }
  > h1 {
    font-family: "Poppins", sans-serif;
    font-size: 47px;
    font-weight: 700;

    @media (max-width: 1024px) {
      font-size: 30px;
    }

    @media (max-width: 768px) {
      font-size: 47px;
    }

    @media (max-width: 500px) {
      font-size: 30px;
    }
  }
`;
const ContentText = styled.div`
  > h1 {
    @media (max-width: 1024px) {
      font-size: 40px;
    }

    @media (max-width: 500px) {
      font-size: 33px;
    }
  }

  > p {
    @media (max-width: 1024px) {
      font-size: 14px;
    }

    @media (max-width: 768px) {
      font-size: 16px;
    }

    @media (max-width: 500px) {
      width: 300px;
      font-size: 14px;
    }
  }

  .strong {
    > a {
      display: flex;
      align-items: center;
      text-decoration: none;
      margin-top: 10px;
      color: ${ThemeConfig.white};

      > img {
        width: 25px;
        margin-right: 10px;
      }
    }
  }
`;

const SectionContratacao = styled.section`
  width: 100%;
  background: ${ThemeConfig.modes.light.background};
`;
const ContentContratacao = styled.div`
  width: 100%;
  max-width: 1280px;
  padding: 50px 30px;
  margin: 0 auto;
  text-align: right;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: center;
  /* align-content: space-around; */

  & h2 {
    font-size: 36px;
    font-weight: 600;
    line-height: 40px;
    margin-bottom: 30px;
  }
  & p {
    font-size: 17px;
    color: ${ThemeConfig.modes.light.text};
    margin-bottom: 40px;
  }

  .contratacao {
    margin-right: 100px;
    margin-bottom: 65px;
  }

  /* .btn-contrate {
    margin-right: 508px;
  } */
`;
const ImagemContratacao = styled.img`
  display: block;
  flex-grow: 0;
  flex-shrink: 1;
  flex-basis: auto;
  align-self: auto;
  order: 0;
  max-width: 530px;
  margin: 50px 0;
`;
const SectionProposito = styled.section`
  width: 100%;
  background: ${ThemeConfig.modes.dark.background};

  .img-chave {
    transform: scaleX(-1);
    width: 240px;

    @media screen and (max-width: 1366px) {
      width: 165px;
    }
  }

  .contente-proposito {
    max-width: 1280px;
    width: 100%;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: space-between;

    margin: auto;
  }

  .div-img {
    width: 200px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
  }

  .div-text {
    flex: 1;
    display: flex;
    flex-direction: column;

    > h2 {
      font-family: "Poppins", sans-serif;
      font-size: 49px;
      font-weight: 700;
      align-self: center;
      padding: 50px 0 30px 0;
      color: ${ThemeConfig.white};

      @media screen and (max-width: 1366px) {
        font-size: 39px;
      }
    }

    > p {
      padding: 20px 0;
      line-height: 30px;
      text-align: center !important;

      font-family: "Poppins", sans-serif;
      font-size: 20px;
      font-weight: 400;
      color: ${ThemeConfig.white};

      > i {
        font-family: "Poppins", sans-serif;
        font-size: 20px;
        font-style: italic;
        font-weight: 700;
        color: ${ThemeConfig.secondary};
      }

      &:last-child {
        margin-bottom: 100px;
      }

      @media screen and (max-width: 1366px) {
        font-size: 16px;
      }
    }
  }

  .div-img-barra {
    width: 200px;
    height: 86vh;
    display: flex;
    align-items: flex-end;

    > span {
      width: 35px;
      height: 260px;
      margin-bottom: -15px;
      background: ${ThemeConfig.secondary};

      @media screen and (max-width: 1366px) {
        width: 20px;
        margin-bottom: -90px;
      }
    }
  }
`;
const SectionCapacitacao = styled.section`
  background: url(${backgroundCapacitacao}) no-repeat right;
  background-size: cover;
  width: 100%;
  height: 100vh;
`;

const ContentCpacitacao = styled.section`
  max-width: 945px;
  width: 100%;
  height: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  text-align: end;

  > h2 {
    font-family: "Poppins", sans-serif;
    font-size: 49px;
    font-weight: 600;
    line-height: 1;
    margin-bottom: 25px;
  }

  > p {
    font-family: "Poppins", sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 1;
    margin-bottom: 25px;
  }
`;

const SectionPosts = styled.section`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  background: ${ThemeConfig.modes.dark.background};

  .btn-posts {
    padding: 10px 40px;

    &:hover {
      border: 3px solid ${ThemeConfig.secondary};
    }
  }

  .btn-divisor {
    width: 100%;
    height: 142px;
    display: flex;
    align-items: flex-start;
    justify-content: center;

    @media screen and (max-width: 1366px) {
      height: 200px;
      align-items: flex-end;
      margin-bottom: 50px;
    }
  }
`;
const ContentCircleHome = styled.div`
  background: url(${stacksImage}) no-repeat left top;
  max-width: 554px;
  width: 100%;
  position: absolute;
  height: 550px;
  right: 0;
  top: 0;
  background-size: 550px;
`;

const ContentPosts = styled.section`
  flex: 1;
  max-width: 1280px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin: auto;

  .card-posts {
    width: 405px;
    height: 600px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 30px;
    border-radius: 10px;
    background: ${ThemeConfig.white};
    transition: all 0.1s;

    @media screen and (max-width: 1366px) {
      margin-top: 25px;
      width: 280px;
      height: 450px;
    }

    &:hover {
      border: 3px solid ${ThemeConfig.secondary};
      width: 410px;
      margin: 0 27px;

      @media screen and (max-width: 1366px) {
        width: 285px;
      }
    }
  }

  .card-img {
    flex: 1;

    > img {
      transition: all 450s ease-out;

      &:hover {
        width: 405px;
      }

      @media screen and (max-width: 1366px) {
        width: 280px;
      }
    }
  }

  .card-text {
    height: 200px;
    padding: 20px;
    margin-top: -7px;
    border-radius: 10px;

    > h3 {
      font-family: "Poppins", sans-serif;
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 10px;

      @media screen and (max-width: 1366px) {
        font-size: 16px;
      }
    }

    > p {
      font-family: "Poppins", sans-serif;
      font-size: 13px;
      font-weight: 400;

      @media screen and (max-width: 1366px) {
        font-size: 10px;
      }
    }
  }
`;

const Footer = styled.section`
  width: 100%;
  height: 215px;
  border-top: 1px solid ${ThemeConfig.secondary};
  background: ${ThemeConfig.backgroundF};
`;

const ContentFooter = styled.section`
  max-width: 1280px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin: auto;

  .logo-sociais {
    flex: 1;
    width: 100%;
    display: flex;
    align-items: flex-start;
  }

  .direitos {
    width: 100%;
    height: 30px;
    font-family: "Poppins", sans-serif;
    font-size: 12px;
    font-weight: 200;
    color: ${ThemeConfig.white};
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .div-left {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 20px;

    > div {
      > h3 {
        font-family: "Poppins", sans-serif;
        font-size: 20px;
        font-weight: 600;
        color: ${ThemeConfig.secondary};
      }

      > p {
        font-family: "Poppins", sans-serif;
        font-size: 12px;
        font-weight: 400;
        color: ${ThemeConfig.textF};
      }
    }
  }

  .div-right {
    flex: 1;
    height: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-around;

    > div {
      display: flex;
      flex-direction: column;

      margin-top: 20px;
      line-height: 20px;

      > h3 {
        font-family: "Poppins", sans-serif;
        font-size: 18px;
        font-weight: 700;
        color: ${ThemeConfig.white};
      }
    }
  }

  .btn-footer {
    border: 1px solid ${ThemeConfig.white};
  }

  .div-social {
    height: 70%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    margin-top: 12px !important;
  }
  .traco {
    width: 75px;
    height: 1px;
    background: ${ThemeConfig.white};
  }
`;

const Li = styled(Link)`
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  font-weight: 200;
  color: ${ThemeConfig.textF};
  text-decoration: none;

  > img {
    padding: 3px;
  }
`;

export {
  SectionHome,
  ContentHome,
  SectionContratacao,
  ContentContratacao,
  ImagemContratacao,
  SectionProposito,
  SectionCapacitacao,
  ContentCpacitacao,
  SectionPosts,
  ContentPosts,
  Footer,
  ContentFooter,
  Li,
  ImageIconHTML,
  ContentCircleHome,
  ImageIconAmazon,
  ImageIconBootstrap,
  ImageIconCSS3,
  ImageIconGraphql,
  ImageIconGatsby,
  ImageIconJavascript,
  ImageIconNodeJs,
  ImageIconReactJs,
  ImageIconTailwindcss,
  ImageIconWP,
  ContentImg,
  ContentText,
};
